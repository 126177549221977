import { Cloud } from "./types";

export const clouds: Cloud[] = [
  {
    key: "1",
    top: "15%",
    left: "73%",
    width: "50%",
    imageName: "c1-f.png",
  },
  {
    key: "2",
    top: "49%",
    left: "60%",
    width: "50%",
    imageName: "c1.png",
  },
  {
    key: "3",
    top: "70%",
    left: "43%",
    width: "50%",
    imageName: "c2-f.png",
  },
  {
    key: "4",
    top: "65%",
    left: "5%",
    width: "50%",
    imageName: "c2.png",
  },
  {
    key: "5",
    top: "24%",
    left: "43%",
    width: "50%",
    imageName: "c1-f.png",
  },
  {
    key: "6",
    top: "3.8%",
    left: "38%",
    width: "50%",
    imageName: "c1.png",
  },
  {
    key: "7",
    top: "-5px",
    mobileTop:"9%",
    left: "26.5%",
    width: "35%",
    imageName: "c1-f.png",
  },

];

export const CloudType = "CloudType";
